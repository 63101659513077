#MainDiv {
    overflow: auto;
    height: 100%;
    position: relative;
}

body {
    background-color: #FFF;
}

a:visited {
    color: black;
}

a {
    color: black;
}

#boxTitle {
    text-align: center;
    width: 100%;
    display: block;
    margin-top: 0;
}

table {
    width: 100%;
    border-spacing:1em;
    word-wrap: anywhere;
    text-align: justify;

}

#infoButton {
    background-color: black;
}

ul {
    margin: 0;
    list-style-position: initial;
    padding-inline-start: 13px;
}

#title_services {
    font-size: 25px;
    margin-bottom: 10px;
    margin-top: 0;
}

#service_container {
    align-items: center;
}

#image {
    border-radius: 10px;
}
