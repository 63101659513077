#MainDivServizi {
    overflow: auto;
    height: 100%;
    position: relative;
}
#mainContainerPercorsoEdu {
    padding-right: 20px;
    padding-left: 20px;
}
#mainContainerDogsitting {
    padding-right: 20px;
    padding-left: 20px;
}

@media (min-width: 930px) {
    #mainContainerPercorsoEdu {
        margin-top: 0px;
    }
    #mainContainerDogsitting {
        margin-top: 10px;
    }
    #title_percorsoedu {
        margin-top: 10px;
        padding-top: 25px;
    }
    #title_dogsitting {
        margin-top: 10px;
        padding-top: 25px;
    }
    #title_recensioni {
        margin-top: 40px;
    }
}

@media (min-width: 624px) and (max-width: 930px) {
    #mainContainerPercorsoEdu {
        margin-top: 60px;
    }
    #mainContainerDogsitting {
        margin-top: 80px;
    }
    #title_percorsoedu {
        padding-top: 25px;
    }
    #title_dogsitting {
        padding-top: 25px;
    }
    #title_recensioni {
        margin-top: 100px;
    }
}

@media (max-width: 624px) {
    #mainContainerPercorsoEdu {
        padding-bottom: 20px;
        margin-bottom: 20px;
        margin-top: 60px;
        padding-right: 20px;
        padding-left: 20px;
    }
    #mainContainerDogsitting {
        margin-top: 80px;
    }
    #title_percorsoedu {
        padding-top: 25px;
        padding-bottom: 30px;
    }
    #title_dogsitting {
        padding-top: 25px;
    }
    #title_recensioni {
        margin-top: 100px;
    }
}

#title_percorsoedu {
    text-align: center;
    font-size: 28px;
    width: 100%;
    height: 55px;
    background-color: white;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 70px;
    z-index: 5;
}

#title_dogsitting {
    text-align: center;
    font-size: 28px;
    width: 100%;
    height: 55px;
    background-color: white;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 70px;
    z-index: 5;
}

#title_recensioni {
    text-align: center;
    font-size: 30px;
    width: 100%;
    background-color: white;
    z-index: 5;
}

#subtitle_ {
    font-weight: normal;
    text-align: left;
    padding: 0;
    margin: 0;
}

#textSection {
    align-self: flex-start;
    text-align: justify;
    padding: 0;
    margin: 5px 0 0;
}
