@font-face {
  font-family: Confortaa;
  src: url(../fonts/Comfortaa.ttf);
  font-display: swap;
}

@font-face {
  font-family: Confortaa_bold;
  src: url(../fonts/Comfortaa-Bold.ttf);
  font-display: swap;
}


html {
  font-size: calc(12px + 0.390625vw);
}

body {
  margin: 0;
  font-family: Confortaa, normal, sans-serif;
  height: 100%;
  width: 100%;
  overflow: auto;
  position: absolute;
  -webkit-font-smoothing: antialiased;
}

p {
  font-family: Confortaa, normal, sans-serif;
}

h1 {
  font-family: Confortaa, normal, sans-serif;
  font-size: 2rem;
  font-weight: normal;
}

h2 {
  font-family: Confortaa, normal, sans-serif;
  font-size: 1.6rem;
  font-weight: normal;
}

h3 {
  font-family: Confortaa, normal, sans-serif;
  font-size: 1.4rem;
  font-weight: normal;
}

h4 {
  font-family: Confortaa, normal, sans-serif;
  font-size: 1.2rem;
  font-weight: normal;
}

h5 {
  font-family: Confortaa, normal, sans-serif;
  font-size: 1.1rem;
  font-weight: normal;
}

h6 {
  font-family: Confortaa, normal, sans-serif;
  font-size: 0.9rem;
  font-weight: normal;
}

h7 {
  font-family: Confortaa, normal, sans-serif;
  font-size: 0.8rem;
  font-weight: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --MAIN-COLOR: #6C05AB
}