#MainDivFormazione {
    background-color: transparent;
    height: 100%;
    position: relative;
    top: 10vh;
    z-index: 2;
    padding-bottom: 4%;
}

#boxedDiv {
    width: 70%;
    height: 20%;
    background-color: white;
    margin: 5% auto;
    line-height: 50px;
    padding: 2%;
    box-shadow: rgba(0, 0, 0, 0.3) 15px 19px 18px, rgba(0, 0, 0, 0.22) 10px 10px 12px;
    border-radius: 5px;
}

#textStory {
    margin-bottom: 5vw;
    font-weight: normal;
    align-self: flex-start;
    padding: 0;
    line-height: 22px;
    font-size: 17px;
}

#formazioneImageRight {
    width: 13vw;
    margin-left: 20px;
    box-shadow: 2px 2px 15px black;
    height: min-content;
    align-self: center;
}

#formazioneImageLeft {
    width: 13vw;
    margin-right: 20px;
    box-shadow: 2px 2px 15px black;
    height: fit-content;
    align-self: center;
}

#subtitleFormazione {
    font-weight: bold;
    align-self: flex-start;
    padding: 0;
    font-size: 22px;
}

#subtitleRecensioni {
    margin-top: -20px;
    font-size: 20px;
    margin-bottom: 20px;
}

#dogName {
    font-size: 25px;
    margin: -20px 0 10px;
}
