#navbarBox {
    height: 72px;
    margin-bottom: 0;
    background-color: var(--MAIN-COLOR);
    width: 100%;
    color: white;
    z-index: 10;
}

#nameMobile {
    text-align: center;
    margin: 0;
}

#jobTitleMobile {
    text-align: center;
    padding: 0;
    margin: 0;
}

#avatar {
    box-shadow: 2px 2px 15px black;
    width: 60px;
    height: 60px;
    margin-right: 10px;
}

#navTextContainer {
    align-self: center;
}

#stack {
    align-items: start;
}

#buttonMenu {
    margin-left: 1vmax;
}

#navbarMobileButton {
    font-size: 20px;
}

#navbarMobileButton:hover {
    background-color: gray;
}
