#link:hover {
    transform: translateY(-3px);
}

#title_contatti {
    text-align: center;
    font-size: 30px;
    width: 100%;
    background-color: white;
    margin-top: 10px;
    margin-bottom: 15px;
    position: sticky;
    top: 70px;
    z-index: 8;
    padding-top: 20px;
    padding-bottom: 10px;
}

#MainDivContatti {
    z-index: 6;
    background-color: white;
    padding-top: 50px;
}
