#firstBlock {
    letter-spacing: 3px;
    overflow: auto;
}

#nameBoxMobile {
    background-color: transparent;
    width: 100%;
    margin-top: -20px;
}

.Typist .Cursor {
    display: inline-block; }
.Typist .Cursor--blinking {
    opacity: 1;
    animation: blink 1s linear infinite; }

@keyframes blink {
    0% {
        opacity: 1; }
    50% {
        opacity: 0; }
    100% {
        opacity: 1; } }

#thirdBlock {
    margin: 20px;
}

#titleThirdBlockDiv {
    background-color: transparent;
    margin: 5% 0 0 0;
}

#titleThirdBlock {
    font-weight: normal;
    text-align: center;
    margin-top: 0;
    border: black;
    color: black;
}

#home_reviews {
    color: black;
    margin: 25px 25px 0;
    letter-spacing: 1px;
    padding: 20px 20px 0;
    /*box-shadow: 3px 1px 20px #530E844D;*/
    font-family: inherit;
    border-radius: 30px 0 30px 30px;
}

#subtitleThirdBlock {
    text-align: left;
    padding-top: 15px;
    padding-bottom: 5px;
}

.alice-carousel__dots {
    margin: 0 !important;
}

.alice-carousel__dots-item:not(.__custom):hover, .alice-carousel__dots-item:not(.__custom).__active {
    background-color: var(--MAIN-COLOR) !important;
}

.alice-carousel__prev-btn-item, .alice-carousel__next-btn-item {
    color: var(--MAIN-COLOR) !important;
    font-size: 30px;
}

ul {
    list-style-position: inside;
}

#name {
    text-align: left;
    border: black;
    font-family: Confortaa_bold, sans-serif;
    color: black;
}


@media (min-width: 624px) and (max-width: 930px) {
    #firstBlock {
        margin-top: 50px;
        margin-left: 20px;
        margin-right: 20px;
    }
    #name {
        margin-bottom: 10px;
        text-align: center;
    }
    #jobTitle {
        font-size: calc(17px + 0.390625vw);
        text-align: left;
        width: 100%;
    }
    #nameBox {
        height: 100px;
        padding-left: 1vw;
    }
    #homeImage {
        width: 31vw;
        margin-right: 20px;
    }
}

@media (min-width: 930px) {
    #firstBlock {
        margin-top: 10px;
        margin-left: 10px;
    }
    #name {
        margin-bottom: 10px;
        text-align: center;
    }
    #jobTitle {
        font-size: calc(17px + 0.390625vw);
        text-align: left;
    }
    #nameBox {
        height: 110px;
        width: 56vw;
        padding-left: 1vw;
    }
    #homeImage {
        width: 35vw;
        margin-right: 30px;
    }
}

@media (max-width: 624px) {
    #firstBlock {
        margin-top: 50px;
        margin-left: 10px;
        margin-right: 10px;

    }
    #name {
        margin-bottom: 10px;
        text-align: center;
        width: 100%;
    }
    #jobTitle {
        font-size: calc(17px + 0.790625vw);
        text-align: left;
    }
    #nameBox {
        width: 100%;
        height: 100px;
    }
    #homeImage {
        width: 80vw;
    }
}

#title_services_home {
    margin-bottom: 0;
    margin-top: 10px;
}

#subtitle_services_home {
    margin-bottom: 4px;
    margin-top: 4px;
    text-align: left;
}

#scopridipiù {
    padding: 10px;
    border-radius: 15px;
    outline: none
}

#scopridipiù:hover {
    font-family: Confortaa_bold, sans-serif;
}

a {
    -webkit-tap-highlight-color: transparent;
}

#mostradipiù {
    text-decoration: underline;
    cursor: pointer;
    color: #595959;
    -webkit-tap-highlight-color: transparent;
}