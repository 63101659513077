#stack {
    height: 72px;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    background-color: var(--MAIN-COLOR);
    /*box-shadow: 1px 1px 17px black; */
    z-index: 10;
    align-items: center;
}

.divButton {
    align-content: center;
    padding: 0;
    color: black;
    vertical-align: middle;
}

#button {
    font-family: inherit;
    letter-spacing: -1px;
    font-size: 25px;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    color: #FFF;
    line-height: 1.5;
    border-radius: 40px;
    padding: 0 10px 0 10px;
    z-index: 4;
}

#button:hover {
    transform: translateY(0px);
    font-family: Confortaa_bold, serif;
}

#avatar_ {
    box-shadow: 2px 2px 15px black;
    width: 120px;
    height: 120px;
}

a:visited {
    color: blue;
}

a:link {
    text-decoration: none;
}

#serviziMenu {
    z-index: 3;
    background-color: var(--MAIN-COLOR);
    font-size: inherit;
    position: relative;
    left: 20px;
    top: 18px;
    margin-left: -30px;
    line-height: 1;
}

#serviziMenuText {
    color: white;
    list-style-type: none;
    align-items: center;
    margin-top: 10px;
    line-height: 1.6;
}

#liNav {
    color: white;
    padding: 10px;
}

#liNav:hover {
    font-family: Confortaa_bold, sans-serif;
    /*background-color: #984FC9;*/
}

#ServiziButton {
    text-align: center;
}